import "./contact.scss";

import { Form, Row, Col, Button } from "react-bootstrap";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import axios from "axios";
import MetaTag from "../metatag";

// import IntlTelInput from "react-intl-tel-input";
// import "react-intl-tel-input/dist/main.css";

function Contact() {
  const { t } = useTranslation();
  const [isSelectedReferral, setReferral] = useState(false);
  const [isSelectedOther, setOther] = useState(false);
  const [validated, setValidated] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    // phoneValid: "",
    // phoneCountry: "",
    // phoneNumber: "",
    email: "",
    howFind: "Search Engine",
    referral: "",
    other: "",
    message: "",
  });

  const captchaChanged = (value) => {
    setCaptchaValue(value);
  };

  const Captcha = async () => {
    try {
      const response = await axios.post("/api/contact-recaptcha", {
        value: captchaValue,
      });
      console.log(response.data.success);
      return response.data.success;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const sendMail = () => {
    console.log(formValues);
    try {
      axios.post("https://cg2management.com/contact-mail", {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        // phoneValid: formValues.phoneValid,
        // phoneCountry: formValues.phoneCountry,
        // phoneNumber: formValues.phoneNumber,
        email: formValues.email,
        howFind: formValues.howFind,
        referral: formValues.referral,
        other: formValues.other,
        message: formValues.message,
      });
      // console.log("success sendMail");
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log(formValues);
  };

  const handleSelect = (e) => {
    if (e.target.value === "Referral") {
      setReferral(true);
      setOther(false);
    } else if (e.target.value === "Other") {
      setReferral(false);
      setOther(true);
    } else {
      setReferral(false);
      setOther(false);
    }

    handleChange(e);
  };

  const hadleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === true) {
      if (captchaValue === null) {
        console.log("Please check the reCAPTCHA");
      } else {
        const captchaResult = await Captcha();

        if (captchaResult) {
          sendMail();
          window.location.reload();
        } else {
          console.log("reCAPTCHA failed");
        }
      }
    }
    setValidated(true);
  };

  return (
    <>
      <MetaTag
        title="Contact Us - CG2 Management"
        description="A family owned management company led by family values and inspired by the vision of a world full of exceptional experiences."
        url="https://cg2management.com/contact"
      />

      <div id="contact-contents">
        <div className="contact-main">
          <h2>CONTACT US</h2>
          <p>{t("contact-contactus-text")}</p>
        </div>
        <div className="contact-form">
          <Form noValidate validated={validated} onSubmit={hadleSubmit}>
            <Row>
              <Form.Group className="mb-3" as={Col} controlId="contactForm.ControlInputFirstName">
                <Form.Label>{t("contact-form-firstname")}</Form.Label>
                <Form.Control
                  required
                  value={formValues.firstName}
                  name="firstName"
                  type="text"
                  maxLength="20"
                  placeholder={t("contact-form-firstname-placeholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{t("contact-form-firstname-invalid")}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" as={Col} controlId="contactForm.ControlInputLastName">
                <Form.Label>
                  {t("contact-form-lastname") + " "}
                  <p className="contact-form-optional">{t("contact-form-optional")}</p>
                </Form.Label>
                <Form.Control
                  name="lastName"
                  value={formValues.lastName}
                  type="text"
                  maxLength="20"
                  placeholder={t("contact-form-lastname-placeholder")}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">{t("contact-form-lastname-invalid")}</Form.Control.Feedback>
              </Form.Group>
            </Row>

            {/* <Form.Group className="mb-3" controlId="contactForm.ControlInputPhone">
            <Form.Label>{t("contact-form-phone")}</Form.Label>
            <IntlTelInput
              name="phone"
              type="tel"
              containerClassName="intl-tel-input"
              inputClassName="form-control"
              telInputProps={{ required: true }}
              preferredCountries={["us", "kr"]}
              onPhoneNumberChange={handlePhoneChange}
              onPhoneNumberBlur={handlePhoneChange}
            />
            <Form.Control.Feedback type="invalid">{t("contact-form-phone-invalid")}</Form.Control.Feedback>
          </Form.Group> */}

            <Form.Group className="mb-3" controlId="contactForm.ControlInputEmail">
              <Form.Label>{t("contact-form-email")}</Form.Label>
              <Form.Control
                required
                name="email"
                value={formValues.email}
                type="email"
                placeholder={t("contact-form-email-placeholder")}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{t("contact-form-email-invalid")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="contactForm.ControlSelectFindus">
              <Form.Label>{t("contact-form-how-find")}</Form.Label>
              <Form.Select required name="howFind" value={formValues.howFind} onChange={handleSelect}>
                <option value="Search Engine">{t("contact-form-how-find-search-engine")}</option>
                <option value="Social Media">{t("contact-form-how-find-social-media")}</option>
                <option value="Referral">{t("contact-form-how-find-referral")}</option>
                <option value="Other">{t("contact-form-how-find-other")}</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">{t("contact-form-how-find-invalid")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={isSelectedReferral ? "mb-3 form-referral" : "mb-3 form-referral hidden"} controlId="contactForm.ControlInputReferral">
              <Form.Label>{t("contact-form-how-find-referral-who")}</Form.Label>
              <Form.Control
                {...(isSelectedReferral ? { required: true } : {})}
                name="referral"
                value={formValues.referral}
                type="text"
                maxLength="45"
                placeholder="Enter your referral"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{t("contact-form-how-find-referral-who-invalid")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              {...(isSelectedOther && "required")}
              className={isSelectedOther ? "mb-3 form-other" : "mb-3 form-other hidden"}
              controlId="contactForm.ControlInputOther"
            >
              <Form.Label>{t("contact-form-how-find-other-else")}</Form.Label>
              <Form.Control
                {...(isSelectedOther ? { required: true } : {})}
                name="other"
                value={formValues.other}
                type="text"
                maxLength="45"
                placeholder="Enter your other reason"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{t("contact-form-how-find-other-else-invalid")}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextareaMessage">
              <Form.Label>{t("contact-form-type-message")}</Form.Label>
              <Form.Control
                required
                name="message"
                value={formValues.message}
                as="textarea"
                maxLength="50"
                rows={3}
                placeholder={t("contact-form-type-message-placeholder")}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">{t("contact-form-type-message-invalid")}</Form.Control.Feedback>
            </Form.Group>
            <ReCAPTCHA className="recaptcha" sitekey="6LeQNGkiAAAAAC0ZrtABQ7-8Ua-M8yXrQOUtzMui" onChange={captchaChanged} />
            <Button type="submit" variant="dark" size="lg">
              SUBMIT
            </Button>
          </Form>
        </div>
        <div className="contact-office-address">
          <h5>Office:</h5>
          <p>{t("contact-office-address")}</p>
          <h5>Email:</h5>
          <p>admin@cg2management.com</p>
          <h5>Phone:</h5>
          <p>(02) 567 7789<br />{t("contact-phone-overseas")}+82(2 567 7789)</p>
        </div>
      </div>
    </>
  );
}

export default Contact;
