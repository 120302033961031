import "./navi.scss";

import React, { useState } from "react";

import { Navbar, Nav } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import ScrollLock from "react-scrolllock";

function Navi() {
  const [burgerOpen, setBurgerOpen] = useState(false);

  const burgerMenuOpen = () => {
    setBurgerOpen(!burgerOpen);
  };

  return (
    <div id="navi-contents">
      <ScrollLock isActive={burgerOpen} />
      <HashLink to="/#" className="header-logo"></HashLink>

      <div className="navi-menu">
        <Navbar>
          <Nav>
            <HashLink to="/#">HOME</HashLink>
            <HashLink to="/about#">ABOUT US</HashLink>
            <HashLink to="/contact#">CONTACT US</HashLink>
          </Nav>
        </Navbar>
      </div>

      <div className="navi-hamburger-trigger" onClick={burgerMenuOpen}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className={burgerOpen ? "navi-menu-right-blind active" : "navi-menu-right-blind"} onClick={burgerMenuOpen}></div>
      <div className={burgerOpen ? "navi-hamburger-trigger active" : "navi-hamburger-trigger"} onClick={burgerMenuOpen}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={burgerOpen ? "navi-menu-right active" : "navi-menu-right"}>
        <HashLink to="/#" onClick={burgerMenuOpen}>
          <div className="navi-menu-right-logo" />
        </HashLink>
        <div className="navi-menu-right-inner">
          <div className="navi-menu-right-item">
            <HashLink to="/#" onClick={burgerMenuOpen}>
              HOME
            </HashLink>
          </div>
          <div className="navi-menu-right-item">
            <HashLink to="/about#" onClick={burgerMenuOpen}>
              ABOUT US
            </HashLink>
          </div>
          <div className="navi-menu-right-item">
            <HashLink to="/contact#" onClick={burgerMenuOpen}>
              CONTACT US
            </HashLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navi;
