import "../reset.scss";
import "./language-select.scss";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setCookie } from "../cookie/cookie.js";

function LanguageSelect() {
  const { i18n } = useTranslation();
  const [isOpen, setMenu] = useState(false);

  const toggleLanguageMenu = () => {
    setMenu(!isOpen);
  };

  const changeLanguage = (lang) => {
    setCookie("lang-code", lang, { maxAge: 31536000 });
    i18n.changeLanguage(lang);
    setMenu(!isOpen);
  };

  let currentLanguage;
  let otherLanguage;
  let otherLanguageCode;

  if ("en" === i18n.language) {
    currentLanguage = "ENGLISH　▼";
    otherLanguage = "KOREAN";
    otherLanguageCode = "ko";
  } else if ("ko" === i18n.language) {
    currentLanguage = "KOREAN　▼";
    otherLanguage = "ENGLISH";
    otherLanguageCode = "en";
  }

  return (
    <div id="dropdown-language">
      <div className="language-item show" onClick={() => toggleLanguageMenu()}>
        {currentLanguage}
      </div>
      <div className={isOpen ? "language-item show" : "language-item"} onClick={() => changeLanguage(otherLanguageCode)}>
        {otherLanguage}
      </div>
    </div>
  );
}

export default LanguageSelect;
