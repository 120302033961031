import "../reset.scss";
import "./footer.scss";

import { HashLink } from "react-router-hash-link";

import LanguageSelect from "./language-select";

function Footer() {
  return (
    <div id="footer-contents">
      <div className="footer-nav">
        <div className="footer-nav-item">
          <HashLink to="/#">HOME</HashLink>
        </div>
        <div className="footer-nav-item">
          <HashLink to="/about#">ABOUT US</HashLink>
        </div>
        <div className="footer-nav-item">
          <HashLink to="/contact#">CONTACT US</HashLink>
        </div>
        <div className="footer-nav-item">
          <HashLink to="/privacy#">PRIVACY POLICY</HashLink>
        </div>
        <LanguageSelect />
      </div>
      <p>© 2022 CG2 Management. All rights reserved.</p>
    </div>
  );
}

export default Footer;
