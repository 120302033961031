import "./App.css";
import "./reset.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Routes, Route } from "react-router-dom";

import Navi from "./layout/navi";
import Home from "./routes/home";
import About from "./routes/about";
import Contact from "./routes/contact";
import Privacy from "./routes/privacy";
import Error from "./routes/error";
import Footer from "./layout/footer";

import { setCookie, getCookie } from "./cookie/cookie.js";
import Initi18n from "./i18n/i18n.js";
import MetaTag from "./metatag";

function App() {
  let langcode = "en";
  if (getCookie("lang-code") === undefined) {
    setCookie("lang-code", "en", { maxAge: 31536000 });
  } else {
    langcode = getCookie("lang-code");
  }
  Initi18n(langcode);

  return (
    <div className="App">
      <MetaTag
        title="CG2 Management"
        description="A family owned management company led by family values and inspired by the vision of a world full of exceptional experiences."
        url="https://cg2management.com/"
      />

      <Navi />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<Error />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
