import React from "react";
import { Helmet } from "react-helmet-async";

const MetaTag = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="%PUBLIC_URL%/assets/favicon/ms-icon-144x144.png" />

      <meta property="og:url" content={props.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:site_name" content="CG2 Management" />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content="%PUBLIC_URL%/assets/opengraph/og-image.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:locale" content="en_US" />
    </Helmet>
  );
};

export default MetaTag;
