import "./privacy.scss";

import { useTranslation } from "react-i18next";
import MetaTag from "../metatag";

function Privacy() {
  const { t } = useTranslation();
  return (
    <>
      <MetaTag
        title="Privacy Policy - CG2 Management"
        description="Your privacy is important to us. This Privacy Policy explains how CG2 Management CG2 manages your Personal Data."
        url="https://cg2management.com/privacy"
      />

      <div id="privacy-contents">
        <div className="privacy-main">
          <div className="privacy-intro">
            <h2>PRIVACY POLICY</h2>
            <p>{t("privacy-main-intro")}</p>
          </div>
          <div className="privacy-main-text">
            <ul>
              <p className="sub-title">{t("privacy-main-personal")}</p>
              <li>{t("privacy-main-personal-text-1")}</li>
              <li>{t("privacy-main-personal-text-2")}</li>
              <li>{t("privacy-main-personal-text-3")}</li>

              <p className="sub-title">{t("privacy-main-data")}</p>
              <li>{t("privacy-main-data-text")}</li>
              <p>
                Name: {t("privacy-main-data-admin-name")}
                <br />
                Email: {t("privacy-main-data-admin-email")}
              </p>

              <p className="sub-title">{t("privacy-main-policy")}</p>
              <li>{t("privacy-main-policy-text")}</li>

              <p className="sub-title">{t("privacy-main-governing")}</p>
              <li>{t("privacy-main-governing-text")}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
