import "../reset.scss";
import "./home.scss";

import { Row, Col } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";

import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <div id="home-contents">
        <div className="home-main">
          <h1>{t("home-main-text")}</h1>
        </div>
        <div className="home-about">
          <h2>ABOUT US</h2>
          <p>{t("home-aboutus-text")}</p>
          <HashLink className="btn" to="/about#">
            {" "}
            READ MORE{" "}
          </HashLink>
        </div>
        <div className="home-ourwork">
          <h2>OUR WORK</h2>
          <p>{t("home-ourwork-text")}</p>
          <div className="home-ourwork-detail">
            <div className="home-ourwork-card">
              <Row>
                <Col className="card-title">
                  <h3>CG2 BRANDS</h3>
                </Col>
                <Col>
                  <p>{t("home-cg2-brands")}</p>
                </Col>
              </Row>
            </div>
            <div className="home-ourwork-card">
              <Row className=" card-alt">
                <Col className="card-title">
                  <h3>CG2 SOCIAL SYSTEMS</h3>
                </Col>
                <Col>
                  <p>{t("home-cg2-social-systems")}</p>
                </Col>
              </Row>
            </div>
            <div className="home-ourwork-card">
              <Row>
                <Col className="card-title">
                  <h3>CG2 SPECIAL PROJECTS</h3>
                </Col>
                <Col>
                  <p>{t("home-cg2-special-projects")}</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
