import "./about.scss";

import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import MetaTag from "../metatag";

function About() {
  const { t } = useTranslation();
  return (
    <>
      <MetaTag
        title="About Us - CG2 Management"
        description="Based in Seoul, South Korea, CG2 Management is comprised of a leadership team with global experience and reach overseeing a team of operation specialists."
        url="https://cg2management.com/about"
      />

      <div id="about-contents">
        <div className="about-main">
          <h2>LEADERSHIP</h2>
          <p>{t("about-leadership-text")}</p>
        </div>
        <div className="about-profile">
          <Row>
            <Col>
              <div className="profile-image jennifer" />
            </Col>
            <Col className="profile-detail">
              <h4>JENNIFER GARANA</h4>
              <p className="detail-biography">Founder & CEO, Entrepreneur</p>
              <p className="detail-text">{t("about-detail-text-jennifer")}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="profile-image chloe" />
            </Col>
            <Col className="profile-detail">
              <h4>CHLOE GARANA</h4>
              <p className="detail-biography">Director of Strategy</p>
              <p className="detail-text">{t("about-detail-text-chloe")}</p>
              <p className="detail-education">{t("about-detail-education")}</p>
              <div className="detail-education-list">
                <div className="detail-education-list-item">
                  <p className="detail-education-list-item-title">{t("about-detail-education-boston-title")}</p>
                  <p className="detail-education-list-item-year">{t("about-detail-education-boston-year")}</p>
                  <p className="detail-education-list-major-year">{t("about-detail-education-boston-major")}</p>
                  <ul className="detail-education-list-career">
                    <li>{t("about-detail-education-boston-career-1")}</li>
                    <li>{t("about-detail-education-boston-career-2")}</li>
                    <li>{t("about-detail-education-boston-career-3")}</li>
                  </ul>
                </div>
                <div className="detail-education-list-item">
                  <p className="detail-education-list-item-title">{t("about-detail-education-ascham-title")}</p>
                  <p className="detail-education-list-item-year">{t("about-detail-education-ascham-year")}</p>
                  <p className="detail-education-list-major-year">{t("about-detail-education-ascham-major")}</p>
                  <ul className="detail-education-list-career">
                    <li>
                      {t("about-detail-education-ascham-career-1")}
                      <ul className="detail-education-list-career-award">
                        <li>{t("about-detail-education-ascham-career-award-1")}</li>
                        <li>{t("about-detail-education-ascham-career-award-2")}</li>
                        <li>{t("about-detail-education-ascham-career-award-3")}</li>
                      </ul>
                    </li>
                    <li>
                      {t("about-detail-education-ascham-career-2")}
                      <ul className="detail-education-list-career-leadership">
                        <li>{t("about-detail-education-ascham-career-leadership-1")}</li>
                        <li>{t("about-detail-education-ascham-career-leadership-2")}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <p className="detail-education-text">{t("about-detail-education-text-chloe")}</p> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default About;
